import http from "../http-common";

class PriceDataService {

  getAll() {
    return http.get("/price")
  }

  get(id) {
    return http.get("/price/"+id)
  }

  newPrice(
    initial_day_interval,
    final_day_interval,
    move_europe,
    move_world,
    move_plus_europe,
    move_plus_world,
    personal_accidents,
    civil_responsability,
    aprc_move_europe,
    aprc_move_world,
    aprc_move_plus_europe,
    aprc_move_plus_world,
    covid,
    covid_move_europe,
    covid_move_world,
    covid_move_plus_europe,
    covid_move_plus_world
  ) {
    return http.post("/price", {
      "initial_day_interval": initial_day_interval,
      "final_day_interval": final_day_interval,
      "move_europe": move_europe,
      "move_world": move_world,
      "move_plus_europe": move_plus_europe,
      "move_plus_world": move_plus_world,
      "personal_accidents": personal_accidents,
      "civil_responsability": civil_responsability,
      "aprc_move_europe": aprc_move_europe,
      "aprc_move_world": aprc_move_world,
      "aprc_move_plus_europe": aprc_move_plus_europe,
      "aprc_move_plus_world": aprc_move_plus_world,
      "covid": covid,
      "covid_move_europe": covid_move_europe,
      "covid_move_world":covid_move_world,
      "covid_move_plus_europe":covid_move_plus_europe,
      "covid_move_plus_world":covid_move_plus_world
    })
  }

  updatePrice(
    id,
    initial_day_interval,
    final_day_interval,
    move_europe,
    move_world,
    move_plus_europe,
    move_plus_world,
    personal_accidents,
    civil_responsability,
    aprc_move_europe,
    aprc_move_world,
    aprc_move_plus_europe,
    aprc_move_plus_world,
    covid,
    covid_move_europe,
    covid_move_world,
    covid_move_plus_europe,
    covid_move_plus_world
  ){
    return http.post("/price/update", {
      "id": id,
      "initial_day_interval": initial_day_interval,
      "final_day_interval": final_day_interval,
      "move_europe": move_europe,
      "move_world": move_world,
      "move_plus_europe": move_plus_europe,
      "move_plus_world": move_plus_world,
      "personal_accidents": personal_accidents,
      "civil_responsability": civil_responsability,
      "aprc_move_europe": aprc_move_europe,
      "aprc_move_world": aprc_move_world,
      "aprc_move_plus_europe": aprc_move_plus_europe,
      "aprc_move_plus_world": aprc_move_plus_world,
      "covid": covid,
      "covid_move_europe": covid_move_europe,
      "covid_move_world": covid_move_world,
      "covid_move_plus_europe": covid_move_plus_europe,
      "covid_move_plus_world": covid_move_plus_world
    })
  }
}

export default new PriceDataService();
