<template>
    <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <CCard>
          <CCardHeader>
            Preços por intervalo de dias
            <CCol col=11 class="d-inline-block text-right">
              <!-- <CButton
                size="sm"
                color="success"
                @click="newPrice" 
              >
                <span>Novo Preço</span>
              </CButton> -->
            </CCol>
          </CCardHeader>
          <CCardBody>
            <CDataTable
              hover
              striped
              :items="items"
              :fields="fieldsArray"
              :items-per-page="perPage"
              @row-clicked="rowClicked"
              :pagination="$options.paginationProps"
              index-column
              clickable-rows
            >
              
            </CDataTable>
          </CCardBody>
        </CCard>
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import Prices from "@/services/PriceDataService";

export default {
  name: 'Prices',
  data: () => {
    return {
      items: [],
      fieldsArray: [
        { key: 'initial_day_interval', label: 'De', sorter: 1 },
        { key: 'final_day_interval', label: 'Até', sorter: 1 },
        { key: 'move_europe', label: 'MOVE Europa' },
        { key: 'move_world', label: 'MOVE Resto do Mundo' },
        { key: 'move_plus_europe', label: 'MOVE Plus Europa' },
        { key: 'move_plus_world', label: 'MOVE Plus Resto do Mundo' },
        //{ key: 'civil_responsability', label: 'Civil Responsability' },
        //{ key: 'personal_accidents', label: 'Personal Accidents' },
        { key: 'aprc_move_europe', label: 'AP + RC MOVE Europa' },
        { key: 'aprc_move_world', label: 'AP + RC MOVE Resto do Mundo' },
        { key: 'aprc_move_plus_europe', label: 'AP + RC MOVE Plus Europa' },
        { key: 'aprc_move_plus_world', label: 'AP + RC MOVE Plus Resto do Mundo' },
        //{ key: 'covid', label: 'Covid em Viagem' },
        { key: 'covid_move_europe', label: 'Covid MOVE Europa' },
        { key: 'covid_move_world', label: 'Covid MOVE Resto do Mundo' },
        { key: 'covid_move_plus_europe', label: 'Covid MOVE Plus Europa' },
        { key: 'covid_move_plus_world', label: 'Covid MOVE Plus Resto do Mundo' },
      ],
      perPage: 25,
      largeModal: false,
      options: ['admin'],

    }
  },
  paginationProps: {
    align: 'center',
    doubleArrows: false,
    previousButtonHtml: 'prev',
    nextButtonHtml: 'next'
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_ADMIN');
      }

      return false;
    },
    showModeratorBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_MODERATOR');
      }

      return false;
    },
    showAdminOrModeratorBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_MODERATOR') || this.currentUser.roles.includes('ROLE_ADMIN');
      }

      return false;
    },
  },
  methods: {
    rowClicked (item, index) {
      this.$router.push({path: "prices/"+item.id})
    },
    // newPrice (){
    //   this.$router.push({path: 'prices/new'})
    // }
  },
  mounted () {
    if (!this.currentUser) {
      this.$router.push('/login');
    }
    Prices.getAll()
      .then(response => {
        this.items = response.data;

      })
      .catch(e => {
        alert(e);
      });
  }
}
</script>